import React from "react";
import "./Picture.scss";

export default function Picture({
  src,
  webp = false,
  alt = "",
  width = "auto",
  height = "auto",
  type = "",
  ...props
}) {
  return (
    <picture className={`picture ${type}`} {...props}>
      {webp && (
        <source
          srcSet={webp}
          alt={alt}
          type="image/webp"
          width={width}
          height={height}
        />
      )}
      <source
        srcSet={src}
        alt={alt}
        type="image/jpeg"
        width={width}
        height={height}
      />
      <img src={src} alt={alt} width={width || " auto"} height="auto" />
    </picture>
  );
}
