import React from "react";

import "./Page.scss";

export default function Page({ children, page }) {
  return (
    <div className={`page ${page === "wol" ? "page--warriors" : ""}`}>
      {children}
    </div>
  );
}
