import React from "react";
import { Helmet } from "react-helmet";

export default function Seo({ pagetitle }) {
  const metaTitle = pagetitle || "";
  const metaDescription =
    "America’s #1 point-of-sale platform for sustainable home solutions. We partner with sustainable home improvement professionals across the country to make sustainable home upgrades simple, with flexible payment options that fit any budget.";

  const thumbnail = "https://loanpal.com/loanpal.jpg";
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en-US",
        }}
        title={metaTitle}
        titleTemplate={(metaTitle && `Loanpal | %s`) || "Loanpal"}
        meta={[
          {
            name: "description",
            content: metaDescription,
          },
          {
            property: "og:title",
            content: metaTitle,
          },
          {
            property: "og:description",
            content: metaDescription,
          },
          {
            property: "og:type",
            content: "website",
          },
          {
            property: "og:image",
            content: thumbnail,
          },
          {
            property: "og:url",
            content: "https://loanpal.com/",
          },
          {
            name: "twitter:card",
            content: "summary_large_image",
          },
          {
            name: "twitter:creator",
            content: "Loanpal",
          },
          {
            name: "twitter:title",
            content: metaTitle,
          },
          {
            name: "twitter:description",
            content: metaDescription,
          },
          {
            property: "twitter:image",
            content: thumbnail,
          },
        ].concat()}
      />
    </>
  );
}
