import React from "react";
import "./BackgroundGuides.scss";

export default function BackgroundGuides({ guides = 3, guideType = "left" }) {
  return (
    <div className="background-guides">
      <div
        className={`background-guides__container background-guides__container--${guideType}`}
      >
        <div className="background-guides__guide" />
        {guides > 1 && <div className="background-guides__guide" />}
        {guides > 2 && <div className="background-guides__guide" />}
      </div>
    </div>
  );
}
