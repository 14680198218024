import "./Footer.scss";

import IconFacebook from "assets/icons/facebook.svg";
import IconInstagram from "assets/icons/instagram.svg";
import IconLinkedin from "assets/icons/linkedin.svg";
import IconTwitter from "assets/icons/twitter.svg";
import IconZillow from "assets/icons/z-icon.svg";
import { Link } from "gatsby";
import Picture from "components/Picture";
import PictureFooter1 from "assets/images/aicpa.png";
import PictureFooter1Webp from "assets/images/aicpa.webp";
import PictureFooter2 from "assets/images/acc_buisness.png";
import PictureFooter2Webp from "assets/images/acc_buisness.webp";
import PictureFooter3 from "assets/images/equal_housing.png";
import PictureFooter3Webp from "assets/images/equal_housing.webp";
import PictureFooter4 from "assets/images/consumer_access.png";
import PictureFooter4Webp from "assets/images/consumer_access.webp";
import React from "react";

export default function Footer({ footerDisclaimer }) {
  const links = [
    {
      text: "home",
      to: "/",
      external: false,
    },
    {
      text: "state licenses",
      to: "/licenses/",
      external: false,
    },
    {
      text: "careers",
      to: "http://jobs.jobvite.com/loanpal/",
      external: true,
    },
    {
      text: "warriors of light",
      to: "/warriors-of-light/",
      external: false,
    },
    {
      text: "privacy policy",
      to: "/privacy/",
      external: false,
    },
    {
      text: "report a bug",
      to: "/bugs/",
      external: false,
    },
    {
      text: "do not sell my information",
      to: "/do-not-sell-my-information/",
      external: false,
    },
    {
      text: "faq",
      to: "/faq/",
      external: false,
    },
    {
      text: "consumer complaints",
      to: "/complaints/",
      external: false,
    },
    { text: "about us", to: "/about-us/", external: false },
    {
      text: "givepower",
      to: "http://givepower.org/",
      external: true,
    },
    {
      text: "contact us",
      to: "/contact-us/",
      external: false,
    },
    {
      text: "terms & conditions",
      to: "/terms/",
      external: false,
    },
    {
      text: "accessibility statement",
      to: "/accessibility-statement/",
      external: false,
    },
    {
      text: "covid-19 resource guide",
      to: "/covid-19/",
      external: false,
    },
  ];

  return (
    <>
      <footer className="footer">
        <div className="footer__container">
          <nav className="nav-footer">
            {links.map((link, index) => (
              <div className="nav-footer__element" key={index}>
                {link.external && link.text && (
                  <a
                    href={link.to}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-describedby={`Open ${link.text} in a new tab`}
                  >
                    {link.text}
                  </a>
                )}
                {!link.external && link.text && (
                  <Link to={link.to}>{link.text}</Link>
                )}
              </div>
            ))}
          </nav>
          <p>Loanpal, LLC.</p>
          <br />
          <p>
            This site is not authorized by the New York State Department of
            Financial Services. No mortgage solicitation activity or loan
            applications for properties located in the State of New York can be
            facilitated through this site.
          </p>
          {footerDisclaimer.length > 0 && <p>{footerDisclaimer}</p>}
          <br />
          <p>
            Loanpal, LLC. NMLS #30336. 8781 Sierra College Blvd. Roseville, CA
            95661
          </p>
          <p>
            LOANPAL is registered trademark of Loanpal, LLC. Copyright{" "}
            {new Date().getFullYear()} Loanpal, LLC - All rights reserved.
          </p>
          <div className="footer-logos__container">
            <div className="footer-logos__row">
              <Picture
                src={PictureFooter1}
                webp={PictureFooter1Webp}
                width="135"
                height="138"
                alt="AICPA Logo"
              />

              <Picture
                src={PictureFooter2}
                webp={PictureFooter2Webp}
                width="177"
                height="100"
                alt="BBB Logo"
              />

              <Picture
                src={PictureFooter3}
                webp={PictureFooter3Webp}
                width="283"
                alt="Equal Housing Logo"
              />

              <Picture
                src={PictureFooter4}
                webp={PictureFooter4Webp}
                width="227"
                alt="Consumer Access Logo"
              />
            </div>
            <div className="footer-logos__row footer-logos__row--second">
              <a
                href="https://www.facebook.com/1Loanpal/"
                target="_blank"
                rel="noopener noreferrer"
                text="Visit Loanpal in Facebook"
                aria-label="Visit Loanpal in Facebook"
                aria-describedby="Open Loanpal's Facebook page in a new tab"
              >
                <Picture src={IconFacebook} alt="Facebook Icon" />
              </a>
              <a
                href="https://twitter.com/Loanpal"
                target="_blank"
                rel="noopener noreferrer"
                text="Visit Loanpal in Twitter"
                aria-label="Visit Loanpal in Twitter"
                aria-describedby="Open Loanpal's Twitter page in a new tab"
              >
                <Picture src={IconTwitter} alt="Twitter Icon" />
              </a>{" "}
              <a
                href="https://www.linkedin.com/company/loanpal/"
                target="_blank"
                rel="noopener noreferrer"
                text="Visit Loanpal in Linkedin"
                aria-label="Visit Loanpal in Linkedin"
                aria-describedby="Open Loanpal's Linkedin page in a new tab"
              >
                <Picture src={IconLinkedin} alt="Linkedin Icon" />
              </a>
              <a
                href="https://www.instagram.com/loanpal/"
                target="_blank"
                rel="noopener noreferrer"
                text="Visit Loanpal in Instagram"
                aria-label="Visit Loanpal in Instagram"
                aria-describedby="Open Loanpal's Instagram page in a new tab"
              >
                <Picture src={IconInstagram} alt="Instagram Icon" />
              </a>
              <a
                href="https://www.zillow.com/lender-profile/1loanpal/#reviews"
                target="_blank"
                rel="noopener noreferrer"
                text="Visit Loanpal in Zillow"
                aria-label="Visit Loanpal in Zillow"
                aria-describedby="Open Loanpal's Zillow page in a new tab"
              >
                <Picture src={IconZillow} alt="Zillow Icon" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
