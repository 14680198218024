import React, { useRef } from "react";
import "./Button.scss";

// import anime from "animejs/lib/anime.es.js";

export default function Button({
  children = "Next",
  handleClick,
  appearance = false,
  caret = false,
  ...props
}) {
  return (
    <div className="button-wrapper">
      <button
        type="button"
        className={`button ${appearance ? `button--${appearance}` : ""}`}
        onClick={handleClick}
        aria-label={children}
      >
        <span>
          {children}
          {caret && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8.621"
              height="14.242"
              viewBox="0 0 8.621 14.242"
              role="img"
            >
              <path
                d="M-16464.961-2813.023l3.75,3.75,1.25,1.25-5,5"
                transform="translate(16467.082 2815.144)"
                fill="none"
                stroke="#555555"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </svg>
          )}
        </span>
      </button>
    </div>
  );
}
