import React from "react";
import BackgroundGuides from "components/BackgroundGuides";

import "./Block.scss";

export default function Block({
  children,
  layer = 1,
  guides = false,
  guideType = "left",
  appearance = "normal",
  ...props
}) {
  return (
    <div
      className={`Block Block--${appearance}`}
      style={{ zIndex: layer }}
      {...props}
    >
      {guides > 0 && (
        <div className="Block__guides">
          <BackgroundGuides guides={guides} guideType={guideType} />
        </div>
      )}
      <div className="Block__content">{children}</div>
    </div>
  );
}
