import React from "react";
import { Link } from "gatsby";
import { useState, useEffect } from "react";
import "./Navigation.scss";
// import "./NavMobile.scss";

import logo from "assets/images/logo_white.svg";
import logoGray from "assets/images/logo_gray.svg";
import close from "assets/icons/close.svg";

const linksPrincipal = [
  {
    text: "For Partners",
    to: "/partners/",
    external: false,
    enabled: true,
  },
  {
    text: "For Homeowners",
    to: "/homeowners/",
    external: false,
    enabled: true,
  },
  {
    text: "About Us",
    to: "/about-us/",
    external: false,
    enabled: true,
  },

  {
    text: "Mission",
    to: "/mission/",
    external: false,
    enabled: true,
  },
  {
    text: "Contact us",
    to: "/contact-us",
    external: false,
    enabled: true,
  },
];

const linksSecondary = [
  {
    text: "Partner Center",
    to: "",
    external: false,
    enabled: true,

    sub: [
      {
        text: "Partner Portal",
        to: "https://partner-admin.loanpal.com/login",
        external: true,
        enabled: true,
      },
      {
        text: "Investor Portal",
        to: "https://investors.loanpal.com/login",
        external: true,
        enabled: true,
      },
    ],
  },
  {
    text: "My Account",
    to: "/my-account",
    external: true,
    enabled: true,

    sub: [
      {
        text: "Home Loan Payment",
        to:
          "https://www.yourmortgageonline.com/?conn=B66F1218-E20C-4EDB-9C45-7B9DE96EFE9F",
        external: true,
        enabled: true,
      },
      {
        text: "Solar Loan Payment",
        to: "https://www.billerpayments.com/app/cust/login.do?bsn=loanpalmain",
        external: true,
        enabled: true,
      },
    ],
  },
  {
    text: "Get started",
    to: "/get-started",
    external: false,
    enabled: false,
    cta: true,
  },
];

const GroupedMenu = ({ sub = "", children }) => {
  return (
    <div className="GroupedMenu">
      <div className="GroupedMenu__Name">{children}</div>
      <ul className="GroupedMenu__Options">
        {sub
          .filter(({ enabled }) => enabled)
          .map(({ text, to, external }, index) => (
            <li key={index}>
              {external && (
                <a
                  href={to}
                  className="GroupedMenu__Link"
                  text={text}
                  text="Homepage"
                >
                  {text}
                </a>
              )}
            </li>
          ))}
      </ul>
    </div>
  );
};

const useSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

export default function Navigation({ menuOpen, setMenuOpen, page }) {
  const handleMenuOpen = () => setMenuOpen(true);
  const handleMenuClose = () => setMenuOpen(false);
  const size = useSize();

  return (
    <>
      <nav className="navigation">
        <div
          className={`navigation__container  navigation__container--${page}`}
        >
          <div className="navigation__logo">
            <Link
              to="/"
              aria-current="page"
              text={`Loanpal Homepage`}
              aria-label={`Loanpal Homepage`}
              aria-describedby={`Go to the homepage`}
            >
              <img
                src={`${
                  page === "direct-pay" && size.width >= 1024 ? logoGray : logo
                }`}
                alt="LoanPal"
              />
            </Link>
          </div>
          <nav className="navigation__menu navigation__menu--principal">
            {linksPrincipal
              .filter(({ enabled }) => enabled)
              .map(({ text, to, external }, index) => (
                <div key={"main" + index}>
                  {!external && (
                    <Link to={to} key={text} className="navigation__link">
                      {text}
                    </Link>
                  )}
                  {external && (
                    <a
                      href={to}
                      key={text}
                      className="navigation__link"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-describedby={`Open ${text} in a new tab`}
                    >
                      {text}
                    </a>
                  )}
                </div>
              ))}
          </nav>

          <nav className="navigation__menu navigation__menu--secondary">
            {linksSecondary
              .filter(({ enabled }) => enabled)
              .map(({ text, to, sub = false }, index) => (
                <div key={"a" + index}>
                  {!sub && (
                    <Link to={to} key={text} className="navigation__link">
                      {text}
                    </Link>
                  )}
                  {sub && (
                    <GroupedMenu
                      key={text}
                      text={text}
                      to={to}
                      cta={false}
                      sub={sub}
                    >
                      {text}
                    </GroupedMenu>
                  )}
                </div>
              ))}
          </nav>
          <button
            className={`navigation__hamburger navigation__hamburger--${page}`}
            type="button"
            aria-label="open mobile menu"
            onClick={() => handleMenuOpen()}
          >
            <span />
            <span />
            <span />
          </button>
        </div>
      </nav>

      <div
        className={`navigation-mobile navigation-mobile--${
          menuOpen ? "open" : "closed"
        }`}
      >
        <div
          className="navigation-mobile__overlay"
          onClick={() => handleMenuClose()}
          onKeyDown={() => handleMenuClose()}
          role="presentation"
          aria-label="Close mobile menu"
        />
        <div className="navigation-mobile__wrapper">
          <div className="navigation-mobile__container">
            <button
              className="navigation-mobile__close"
              type="button"
              onClick={() => handleMenuClose()}
              aria-label="Close mobile menu"
            >
              <img src={close} alt="Close" />
            </button>
            <div className="navigation-mobile__menu">
              {/* */}
              <div className="navigation-mobile__menu_top">
                <Link to="/" className="navigation-mobile__link">
                  Home
                </Link>
                <Link to="/partners/" className="navigation-mobile__link">
                  For Partners
                </Link>
                <Link to="/homeowners/" className="navigation-mobile__link">
                  For Homeowners
                </Link>
                <Link to="/about-us/" className="navigation-mobile__link">
                  About Us
                </Link>
                <Link to="/mission/" className="navigation-mobile__link">
                  Mission
                </Link>
                <Link to="/contact-us/" className="navigation-mobile__link">
                  Contact Us
                </Link>
              </div>
              {/* */}
              {/* */}
              <div className="navigation-mobile__menu_bottom">
                <a
                  href="https://partner-admin.loanpal.com/login"
                  className="navigation-mobile__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-describedby={`Partner Portal`}
                >
                  Partner Portal
                </a>
                <a
                  href="https://investors.loanpal.com/login"
                  className="navigation-mobile__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-describedby={`Open Investor Portal in a new tab`}
                >
                  Investor Portal
                </a>
                <a
                  href="https://www.yourmortgageonline.com/?conn=B66F1218-E20C-4EDB-9C45-7B9DE96EFE9F"
                  className="navigation-mobile__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-describedby={`Open Home Loan Payment in a new tab`}
                >
                  Home Loan Payment
                </a>
                <a
                  href="https://www.billerpayments.com/app/cust/login.do?bsn=loanpalmain"
                  className="navigation-mobile__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-describedby={`Open Solar Loan Payment in a new tab`}
                >
                  Solar Loan Payment
                </a>
              </div>
              {/* */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
