import "normalize.css";
import "fontsource-nunito";
import "react-modal-video/scss/modal-video.scss";
import "react-responsive-modal/styles.css";
import "styles/app.scss";
import "./Main.scss";

import React, { useEffect, useState } from "react";

import Footer from "components/layout/Footer";
import Navigation from "components/layout/Navigation";
import NavigationRef from "components/layout/NavigationRef";
import Page from "components/layout/Page";
// import a11yChecker from "a11y-checker";
import Seo from "components/layout/Seo";

export default function Layout({
  pagetitle,
  children,
  page,
  navStyle = "regular",
  footerDisclaimer = "",
}) {
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    // a11yChecker();
  }, []);

  return (
    <>
      <div className="main">
        <Seo pagetitle={pagetitle} />

        {navStyle === "regular" && (
          <Navigation
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            page={page}
          />
        )}

        {navStyle === "wolRef" && (
          <NavigationRef
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            page={page}
          />
        )}

        <div className="main__content">
          <Page page={page}>{children}</Page>
          <Footer footerDisclaimer={footerDisclaimer} />
        </div>
      </div>
    </>
  );
}
