import React from "react";
import { Link } from "gatsby";
import "./NavigationRef.scss";

import Button from "components/Button";
import logo from "assets/images/logo_color.svg";
import close from "assets/icons/close.svg";

export default function Navigation({ menuOpen, setMenuOpen, page }) {
  const handleMenuOpen = () => setMenuOpen(true);
  const handleMenuClose = () => setMenuOpen(false);

  return (
    <>
      <nav className="navigation">
        <div
          className={`navigation__container  navigation__container--${page}`}
        >
          <div className="navigation__logo">
            <Link
              to="/"
              aria-current="page"
              text={`Loanpal Homepage`}
              aria-label={`Loanpal Homepage`}
              aria-describedby={`Go to the homepage`}
            >
              <img src={`${logo}`} alt="LoanPal" />
            </Link>
          </div>

          <div className="navigation-ref__menu">
            <a
              href="#form"
              className="button button--orange-slim"
              aria-label="Learn more"
            >
              Learn more
            </a>
            <a
              href="https://www.goloanpal.com/borrower/onboarding/GPETERSEN@LOANPAL.COM"
              target="_blank"
              rel="noopener noreferrer"
              className="button button--orange-slim"
              aria-label="Apply now"
            >
              Apply now
            </a>
          </div>

          <button
            className={`navigation__hamburger navigation__hamburger--${page}`}
            type="button"
            aria-label="open mobile menu"
            onClick={() => handleMenuOpen()}
          >
            <span />
            <span />
            <span />
          </button>
        </div>
      </nav>

      <div
        className={`navigation-mobile navigation-mobile--${
          menuOpen ? "open" : "closed"
        }`}
      >
        <div
          className="navigation-mobile__overlay"
          onClick={() => handleMenuClose()}
          onKeyDown={() => handleMenuClose()}
          role="presentation"
          aria-label="Close mobile menu"
        />
        <div className="navigation-mobile__wrapper">
          <div className="navigation-mobile__container">
            <button
              className="navigation-mobile__close"
              type="button"
              onClick={() => handleMenuClose()}
              aria-label="Close mobile menu"
            >
              <img src={close} alt="Close" />
            </button>
            <div className="navigation-mobile__menu">
              {/* */}
              <div className="navigation-mobile__menu_top">
                <Link to="/" className="navigation-mobile__link">
                  Home
                </Link>
                <Link to="/what-we-do/" className="navigation-mobile__link">
                  What We Do
                </Link>
                <Link to="/about-us/" className="navigation-mobile__link">
                  About Us
                </Link>
                <Link to="/mission/" className="navigation-mobile__link">
                  Mission
                </Link>
                <Link to="/contact-us/" className="navigation-mobile__link">
                  Contact Us
                </Link>
              </div>
              {/* */}
              {/* */}
              <div className="navigation-mobile__menu_bottom">
                <a
                  href="https://partner-admin.loanpal.com/login"
                  className="navigation-mobile__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-describedby={`Partner Portal`}
                >
                  Partner Portal
                </a>
                <a
                  href="https://investors.loanpal.com/login"
                  className="navigation-mobile__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-describedby={`Open Investor Portal in a new tab`}
                >
                  Investor Portal
                </a>
                <a
                  href="https://www.yourmortgageonline.com/?conn=B66F1218-E20C-4EDB-9C45-7B9DE96EFE9F"
                  className="navigation-mobile__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-describedby={`Open Home Loan Payment in a new tab`}
                >
                  Home Loan Payment
                </a>
                <a
                  href="https://www.billerpayments.com/app/cust/login.do?bsn=loanpalmain"
                  className="navigation-mobile__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-describedby={`Open Solar Loan Payment in a new tab`}
                >
                  Solar Loan Payment
                </a>
              </div>
              {/* */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
